import React from "react"

import { useSelector } from "react-redux"

import SEO from "~/components/Seo/Seo"
import { Container } from "~/components/ui"
import { useProfileQuery } from "~/state/api/profile"
import { authSelector } from "~/state/modules/auth"
import { userSelector } from "~/state/modules/userInfo"
import ProfilePage from "~/templates/Profile/ProfilePage/ProfilePage"
import withTemplate from "~/templates/withTemplate"

// Homepage of private profile (aka: myCheclub)
const Home = () => {
  const { token } = useSelector(authSelector)
  const user = useSelector(userSelector)

  //Refresh the user on load
  useProfileQuery(token)

  if (!user) return null

  return (
    <>
      <SEO
        title="myChefclub"
        meta={[{ name: "robots", content: "noindex,nofollow" }]}
      />

      <ProfilePage user={user} privacy="Private" />
    </>
  )
}

export default withTemplate(Home)
